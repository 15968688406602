import { render, staticRenderFns } from "./Guidances.vue?vue&type=template&id=4caf7a08&scoped=true&"
import script from "./Guidances.vue?vue&type=script&lang=js&"
export * from "./Guidances.vue?vue&type=script&lang=js&"
import style0 from "./Guidances.vue?vue&type=style&index=0&id=4caf7a08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4caf7a08",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4caf7a08')) {
      api.createRecord('4caf7a08', component.options)
    } else {
      api.reload('4caf7a08', component.options)
    }
    module.hot.accept("./Guidances.vue?vue&type=template&id=4caf7a08&scoped=true&", function () {
      api.rerender('4caf7a08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/faculty/guidances/Guidances.vue"
export default component.exports