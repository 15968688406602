<template>
  <v-container>
      <h1>Professores</h1>
  </v-container>
</template>

<script>


export default {
  name: "Guidances",
  components: {  },
  data() {
    return {
   
    };
  },

  created() {
  
  },

  methods: {
  
  },
  computed: {
   
  },
  watch: {
   
  }
};
</script>

<style scoped>
td {
  cursor: pointer;
}

.md2 {
  max-width: none;
}

fieldset {
  max-width: 60%;
  margin: auto;
}
</style>